import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { ContactUsData, FunctionsEndpoint } from '../shared/constants';
import { ContactUs } from '../components/ContactUs';
import * as Http from '../shared/Http';

export const ContactUsContainer = ({ label, isFullWidth = false }) => {
  const [open, setOpen] = useState(false);
  const [form, setValues] = useState({
    name: '',
    email: '',
    message: '',
    showNotification: false,
    notificationMessage: ''
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const updateNotification = (show, message) => {
    setValues({
      ...form,
      showNotification: show,
      notificationMessage: message
    });
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    const isValidNameAndMessage = form.name.trim().length > 0 && form.message.trim().length > 0;
    if (!isValidNameAndMessage) {
      updateNotification(true, ContactUsData.messages.empty);
      return;
    }
    const isValidEmail = isEmail(form.email);
    if (!isValidEmail) {
      updateNotification(true, ContactUsData.messages.invalidEmail);
      return;
    }

    Http.POST(FunctionsEndpoint.contact, { name: form.name, email: form.email, message: form.message })
      .then(() => {
        setValues({
          ...form,
          showNotification: true,
          notificationMessage: ContactUsData.messages.thankYou,
          name: '',
          email: '',
          message: ''
        });
      })
      .catch(error => console.log("failed to send email"))
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <ContactUs
      isFullWidth={isFullWidth}
      handleClickOpen={handleClickOpen}
      label={label}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      name={form.name}
      email={form.email}
      message={form.message}
      updateField={updateField}
      showNotification={form.showNotification}
      updateNotification={updateNotification}
      notificationMessage={form.notificationMessage}
    />
  );
};
