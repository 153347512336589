import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Close} from "@material-ui/icons";
import Typography from '@material-ui/core/Typography';
import { Send } from '@material-ui/icons';
import { Grid, makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import ContactUsForm from './ContactUsForm';
import Slide from '@material-ui/core/Slide';
import { ContactUsData } from '../../shared/constants';

/**
 * React Hooks example of form from
 * https://daveceddia.com/usestate-hook-examples/
 */

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ContactUs = ({
  isFullWidth,
  handleClickOpen,
  label,
  open,
  handleClose,
  handleSubmit,
  name,
  email,
  message,
  updateField,
  showNotification,
  updateNotification,
  notificationMessage
}) => {
  const classes = useStyles();
  return (
    <div>
      <Button aria-label={label} size={'large'} variant="contained" fullWidth={isFullWidth} color="primary" onClick={handleClickOpen}>
        {label}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Drop us a line
            </Typography>
            <IconButton arial-label={'Send'} color="inherit" onClick={handleSubmit}>
              <Send />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container justify={'center'}>
          <Grid item xs={10} md={6}>
            <ContactUsForm name={name} email={email} message={message} onChange={updateField} />
          </Grid>
        </Grid>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showNotification}
        autoHideDuration={ContactUsData.autoHideDuration}
        onClose={() => updateNotification(false, '')}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{notificationMessage}</span>}
      />
    </div>
  );
};
