import React from 'react';
import { Grid, TextField, Typography, withStyles } from '@material-ui/core';

const ContactUsForm = ({ classes, name, email, message, onChange }) => (
  <>
    <Grid container justify={'center'} className={classes.header}>
      <Grid item xs={12}>
        <Typography variant={'body2'}>
          If you prefer to send email, reach us at
          <span className={classes.span}>
            <a alt="bonsaiilabs email" href="mailto:team@bonsaiilabs.com">
              team@bonsaiilabs.com
            </a>
          </span>
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={2} justify={'center'}>
      <Grid item xs={12} md={7}>
        <TextField fullWidth id="standard-name" name={'name'} label="Name" value={name} onChange={onChange} margin="normal" />
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField fullWidth id="standard-name" name={'email'} label="Email" value={email} onChange={onChange} margin="normal" />
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          fullWidth
          name={'message'}
          label="Message"
          multiline
          rowsMax="4"
          value={message}
          onChange={onChange}
          margin="normal"
        />
      </Grid>
    </Grid>
  </>
);

const styles = theme => ({
  header: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    }
  },
  span: {
    paddingLeft: theme.spacing(0.5)
  }
});

export default withStyles(styles)(ContactUsForm);
